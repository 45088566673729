import React, { Component } from "react";
import "./join-call.css";
import InvalidConsultation from "../../components/consultation/invalid";
import Consultation from "../../components/consultation/consultation";
import ConsultLoader from "../../components/loaders/spinner";
import {
  axiosi
} from "../../utils/constant";
import PropTypes from 'prop-types';

class JoinCallPage extends Component {
  state = {
    component: null,
    loading: true,
    appointment: {}
  };

  static get propTypes() { 
    return {
      match: PropTypes.shape({
        params: PropTypes.shape({
          code: PropTypes.string.isRequired
        })
      })
    };
  }

  async getBooking() {
    let { code } = this.state;
    axiosi
      .get("patients/booking/" + code)
      .then((appointment) => {
        this.setState({
          loading: false,
          component: "consultation",
          appointment: appointment.data.result,
          // appointment: {
          //   ...appointment.data.result,
          //   prescription_url: 'https://opd-emr.s3.us-west-2.amazonaws.com/live/visit-prescription/2021/5/MH.19576-25052021-070732.pdf'
          // }
        });
        if (typeof this.interval === 'undefined' && appointment.data.result.prescription_url == '') {
          this.interval = setInterval(() => this.getBooking(), 60000);
        }
        if (typeof this.interval !== 'undefined' && appointment.data.result.prescription_url != '') {
          clearInterval(this.interval);
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
          component: "expired",
        });
      });
  }

  componentDidMount() {
    try {
      this.setState({
        code: this.props.match.params.code
      }, () => this.getBooking());
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { component, loading, appointment } = this.state;
    return (
      <>
        {loading ? <ConsultLoader /> : null}
        {component === "expired" ? <InvalidConsultation /> : null}
        {component === "consultation" &&
          appointment.booking_code !== undefined ? (
          <Consultation appointment={appointment} />
        ) : null}
      </>
    );
  }
}

export default JoinCallPage;
