import React from "react";
import EndCallOgg from "../../assets/audio/end_of_call.ogg";
import EndCallMp3 from "../../assets/audio/end_of_call.mp3";
import CallingOgg from "../../assets/audio/calling.ogg";
import CallingMp3 from "../../assets/audio/calling.mp3";
import RingtoneOgg from "../../assets/audio/ringtone.ogg";
import RingtoneMp3 from "../../assets/audio/ringtone.mp3";

export default class VcSounds extends React.Component {
    render() {
        return (
            <>
                <audio id="endCallSignal" preload="auto">
                    <source src={EndCallOgg} type="audio/ogg" />
                    <source src={EndCallMp3} type="audio/mp3" />
                </audio>
                <audio id="callingSignal" loop preload="auto">
                    <source src={CallingOgg} type="audio/ogg" />
                    <source src={CallingMp3} type="audio/mp3" />
                </audio>
                <audio id="ringtoneSignal" loop preload="auto">
                    <source src={RingtoneOgg} type="audio/ogg" />
                    <source src={RingtoneMp3} type="audio/mp3" />
                </audio>
            </>
        )
    }
}