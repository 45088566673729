import { Subject } from 'rxjs';

const callDriver = new Subject();
export const callDriverService = {
    init: param => callDriver.next(param),
    status: () => callDriver.asObservable()
};

const call = new Subject();
export const callService = {
    init: param => call.next(param),
    status: () => call.asObservable()
};

const vonageExpandedScreenOpts = new Subject();
export const vonageExpandedScreenService = {
    init: param => vonageExpandedScreenOpts.next(param),
    status: () => vonageExpandedScreenOpts.asObservable()
};

const subject = new Subject();
export const messageService = {
    sendMessage: message => subject.next(message),
    clearMessages: () => subject.next(),
    getMessage: () => subject.asObservable()
};

const IncomingCall = new Subject();
export const IncomingCallService = {
    init: param => IncomingCall.next(param),
    status: () => IncomingCall.asObservable()
};
