import axios from "axios";

export const APP_SUB_FOLDER = process.env.REACT_APP_SUB_FOLDER;
const API_BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL_IOS_V2 = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IOS_V2;
const APP_AGGREGATOR_TOKEN = process.env.REACT_APP_AGGREGATOR_TOKEN;
const APP_AGGREGATOR_TYPE = process.env.REACT_APP_AGGREGATOR_TYPE;
export const API_HEADERS = {
    apitoken: APP_AGGREGATOR_TOKEN,
    aggregatorType: APP_AGGREGATOR_TYPE,
};
export const axiosi = axios.create({
    baseURL: API_URL_IOS_V2,
    headers: API_HEADERS
});

export const CALL_RATING = `${API_BASE_URL}vonage/state`;
export const HOMECARE_CALL_RECORD_RATING = `${API_BASE_URL}doctors/homecare-call`;
export const VONAGE_CALL_API = `${API_BASE_URL}doctor/vonage/call`;
// export const VONAGE_CALL_NOTIFY = (params) => `${API_BASE_URL}vonage/booking/${params.bookingId}/status/${params.type}`;
export const VONAGE_CALL_NOTIFY = `${API_BASE_URL}doctor/vonage/status`;
export const MYHC_APPTYPE = process.env.REACT_APP_OPD_APP_TYPE; /* This value should be one of the keys of APPS_USERTYPES */
export const VONAGE_INCOMING_CALL_SOCKET = process.env.REACT_APP_VONAGE_INCOMING_CALL_SOCKET;
export const APPS_USERTYPES = {
    "woodlands": {
        doctor: 1,
        nurse: 2
    },
    "mhvcmarketplace": {
        doctor: 3,
        nurse: 2
    }
};
export const USERTYPES = APPS_USERTYPES[MYHC_APPTYPE];
export const SUCCESS_CODE = 200;
export const FAILURE_CODE = 400;
export const VC_ACCEPTED = 2;
export const VC_DECLINED = 3;
export const VC_DISCONNECTED = 4;
