import React from 'react';
import MyHcLogo from "../assets/images/logo-myhealthcare.svg";
import AppStoreIcon from "../assets/images/appstore.png";
import PlayStoreIcon from "../assets/images/playstore.png";
import { notification } from "antd";
import {Howl} from 'howler';
import EndCallOgg from "../assets/audio/end_of_call.ogg";
import EndCallMp3 from "../assets/audio/end_of_call.mp3";
import CallingOgg from "../assets/audio/calling.ogg";
import CallingMp3 from "../assets/audio/calling.mp3";
import RingtoneOgg from "../assets/audio/ringtone.ogg";
import RingtoneMp3 from "../assets/audio/ringtone.mp3";

export const IncomingCallTone = new Howl({
  src: [RingtoneOgg, RingtoneMp3],
  loop: true,
  volume: 1.0,
  preload: true
});
export const CallingTone = new Howl({
  src: [CallingOgg, CallingMp3],
  loop: true,
  volume: 1.0,
  preload: true
});
export const EndCallTone = new Howl({
  src: [EndCallOgg, EndCallMp3],
  volume: 1.0,
  preload: true
});

export const SITE_LOGO = (
  <img
    className="img-fluid"
    src={MyHcLogo}
    alt="MyHealthcare Logo"
    width={150}
  />
);

export const APP_ICONS = (
  <div className="col-12 my-3 text-center applogo">
    <a
      href="https://play.google.com/store/apps/details?id=com.innocirc.vcpatient"
      rel="noreferrer"
      target="_blank"
    >
      <img src={PlayStoreIcon} alt="Play store logo" />
    </a>
    &nbsp;
    <a
      href="https://apps.apple.com/in/app/myhealthcare/id1506349869"
      rel="noreferrer"
      target="_blank"
    >
      <img src={AppStoreIcon} alt="App store logo" />
    </a>
  </div>
);

export const LOADER_BTN = (
  <button type="button" className="btn-upload btn-loader mb-1 mr-1">
    <div className="spinner red">
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  </button>
);

export const LOADER = (
  <div className="spinner red">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
  </div>
);

export const notify = (info) => {
  notification.destroy();
  notification.open({
    message: info.title,
    description: info.message,
    placement: "bottomLeft",
  });
};

const handlePermission = (permission) => {
  // set the button to shown or hidden, depending on what the user answers
  console.log('>>> permission', permission)
  // if (
  //   Notification.permission === "denied" ||
  //   Notification.permission === "default"
  // ) {
  //   notificationBtn.style.display = "block";
  // } else {
  //   notificationBtn.style.display = "none";
  // }
}

const checkNotificationPromise = () => {
  try {
    Notification.requestPermission().then();
  } catch(e) {
    return false;
  }

  return true;
}

export const askNotificationPermission = () => {
  // function to actually ask the permissions
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support notifications.");
  } else {
    if (checkNotificationPromise()) {
      Notification.requestPermission().then((permission) => {
        handlePermission(permission);
      });
    } else {
      Notification.requestPermission(function (permission) {
        handlePermission(permission);
      });
    }
  }
};
