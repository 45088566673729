import React, { Component } from "react";
import { Modal } from "antd";
import VideoConferencing from "../../components/video-conferencing/VideoConferencing";

import PdfIcon from "../../assets/images/ic-pdf.svg";
// import ShareIcon from "../../assets/images/ic-share.svg";
import DownloadIcon from "../../assets/images/ic-download.svg";
import VideoIcon from "../../assets/images/ic-video.svg";
import SamsungInternet from "../../assets/images/samsung-internet.png";
import IosSafari from "../../assets/images/safari.png";
import MozillaFirefox from "../../assets/images/firefox.png";
import GoogleChrome from "../../assets/images/google-chrome.png";
import {
  APP_ICONS,
  SITE_LOGO,
  LOADER_BTN,
  notify,
  IncomingCallTone,
  EndCallTone,
} from "../../utils/helpers";
import {
  API_URL_IOS_V2,
  axiosi,
  VC_ACCEPTED,
  VC_DECLINED,
  VC_DISCONNECTED,
  API_HEADERS
} from "../../utils/constant";
import { IncomingCallService } from "../../utils/rxjs-sharing";
// import LS_SERVICE from '../../utils/localStorage';
import PropTypes from 'prop-types';

import { Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Moment from "react-moment";
// import moment from "moment";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "application/pdf";
  if (!isJpgOrPng) {
    notify({
      title: "File Upload Error",
      message: "You can only upload JPG/JPEG/PNG/PDF file!",
    });
  }
  // const isLt2M = file.size / 1024 / 1024 <= 2;
  // if (!isLt2M) {
  //   notify({
  //     title: 'File Upload Error',
  //     message: 'File size cannot be greater than 2MB!'
  //   });
  // }
  // return isJpgOrPng && isLt2M;
  return isJpgOrPng;
}

class Consultation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      callStartedModal: false,
      patient: {
        booking_id: "AAAAAAA123456",
      },
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      prescriptionVisible: false,
      notes: "",
      fileList: [],
      savingNotes: false,
      vcInfo: {},
      permissions: {
        video: false,
        audio: false,
        show: false,
      },
      browserModal: false,
    };
  }

  static get propTypes() { 
    return { 
      appointment: PropTypes.any, 
      location: PropTypes.any 
    };
  }

  componentDidMount() {
    this.appPermissions(true);
    this.compatibleBrowsers();
    // askNotificationPermission();
    // var img = MyHcLogo;
    // let title = 'Salman';
    // var text = 'HEY! Your task "' + title + '" is now overdue.';
    // var notification = new Notification('To do list', { body: text, icon: img });

    this.subscription = IncomingCallService.status().subscribe((result) => {
      // Doctor started the call
      if (
        result.booking_id === this.props.appointment.booking_id &&
        result.type === "patient_join"
      ) {
        this.ring();
        this.setState({
          vcInfo: result,
          callStartedModal: true,
        });
        // var img = MyHcLogo;
        // let title = 'Salman';
        // var text = 'HEY! Your task "' + title + '" is now overdue.';
        // var notification = new Notification('To do list', { body: text, icon: img });
      }

      // Doctor disconnected the call
      if (
        result.booking_id === this.props.appointment.booking_id &&
        result.type === "patient_to_declined"
      ) {
        this.setState({
          vcInfo: {},
          callStartedModal: false,
        });
        notify({
          title: "Video Call Disconnected",
          message: "Call has been disconnected!",
        });
        this.stopring();
      }
    });
  }

  ring() {
    IncomingCallTone.play();
    // document.getElementById("ringtoneSignal").play();
  }

  stopring() {
    IncomingCallTone.pause();
    // document.getElementById("ringtoneSignal").pause();
    EndCallTone.play();
    // document.getElementById("endCallSignal").play();
  }

  componentWillUnmount() {
    if (typeof this.subscription !== "undefined") {
      this.subscription.unsubscribe();
    }
  }

  joinCall() {
    this.stopring();
    this.setState({ callStartedModal: false, joinCall: true });
    this.callStatus(VC_ACCEPTED);
  }

  rejectCall() {
    this.stopring();
    this.setState({ callStartedModal: false });
    this.callStatus(VC_DECLINED);
  }

  callEnded() {
    this.setState({ joinCall: false });
    this.callStatus(VC_DISCONNECTED);
  }

  callStatus(status) {
    const { appointment } = this.props;
    axiosi
      .get("vonage/bookings/" + appointment.booking_id + "/status/" + status);
      // .then((response) => {})
      // .catch((error) => {});
  }

  receiveInParent(callInfo) {
    if (typeof callInfo !== "undefined") {
      console.log("JoinCall receiveInParent callInfo", callInfo);
      // this.setState({
      //   callPatientBtn: callInfo.callPatientBtn,
      //   callNotificationMsg: callInfo.callNotificationMsg,
      //   canEndCall: callInfo.canEndCall,
      //   hungupExistingCall: callInfo.hungupExistingCall || false,
      // });
    }
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => {
    // file, event, 
    // const twomb = 2 * 1024 * 1024;
    const latestOne = fileList[fileList.length - 1];
    // if (latestOne.size > twomb) {
    //   return;
    // }
    if (
      latestOne != undefined &&
      latestOne.response !== undefined &&
      latestOne.response.status === false
    ) {
      notify({
        title: "File Upload Error",
        message: latestOne.response.message,
      });
    }
    if (
      latestOne != undefined &&
      latestOne.response !== undefined &&
      latestOne.response.errors
    ) {
      let messages = [];
      for (let element of latestOne.response.errors) {
        messages.push(element.message);
      }

      notify({
        title: "File Upload Error",
        message: messages.join(", "),
      });
    }

    if (latestOne) {
      const isJpgOrPng =
        latestOne.type === "image/jpeg" ||
        latestOne.type === "image/png" ||
        latestOne.type === "image/jpg" ||
        latestOne.type === "application/pdf";
      if (!isJpgOrPng) {
        latestOne.status = "error";
      }
    }

    this.setState({ fileList });
  };

  async saveNotes() {
    const { notes } = this.state;
    const { appointment } = this.props;
    try {
      this.setState({ savingNotes: true });
      const info = await axiosi.post(
        "bookings/" + appointment.booking_id + "/update-note",
        {
          patient_note: notes.substr(0, 1000),
        }
      );
      this.setState({ savingNotes: false });
      if (info.data.status) {
        notify({
          title: "Notes",
          message: "Notes have been updated successfully!",
        });
      } else {
        notify({
          title: "Notes Error",
          message: info.data.message || "Not able to update notes, try again",
        });
      }
    } catch (err) {
      this.setState({ savingNotes: false });
      notify({
        title: "Notes Error",
        message: err,
      });
    }
  }

  appPermissions(askForAccess) {
    let $this = this;
    window.DetectRTC.load(function () {
      let show =
        !window.DetectRTC.isWebsiteHasWebcamPermissions ||
        !window.DetectRTC.isWebsiteHasMicrophonePermissions
          ? true
          : false;

      $this.setState({
        permissions: {
          video: window.DetectRTC.isWebsiteHasWebcamPermissions,
          audio: window.DetectRTC.isWebsiteHasMicrophonePermissions,
          show: show,
        },
      });

      if (show && askForAccess) {
        let constraints = {};
        if (!window.DetectRTC.isWebsiteHasWebcamPermissions) {
          constraints.video = true;
        }
        if (!window.DetectRTC.isWebsiteHasMicrophonePermissions) {
          constraints.audio = true;
        }

        $this.getAccessForWebcam(constraints);
      }
    });
  }

  getAccessForWebcam(constraints) {
    let $this = this;
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        $this.appPermissions(false);
        stream.getTracks().forEach(function (track) {
          track.stop();
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  compatibleBrowsers() {
    // const then = LS_SERVICE.get('browsers_notified');
    // console.log('>> datetime', then);

    // const now = moment(new Date()); //todays date
    // const end = moment(then); // another date
    // const duration = moment.duration(now.diff(end));
    // const days = duration.asDays();

    this.setState({
      browserModal: true,
    });
    // const duration = moment.duration(end.diff(startTime));
    // const hours = duration.asHours();

    // if (check === null) {
    //   LS_SERVICE.set('browsers_notified', true);
    //   this.setState({
    //     browserModal: true
    //   });
    // }
  }

  render() {
    const {
      permissions,
      vcInfo,
      notes,
      previewVisible,
      previewImage,
      fileList,
      previewTitle,
      prescriptionVisible,
      savingNotes,
    } = this.state;
    const { appointment } = this.props;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <div className="container">
          <div className="row">
            {permissions.show ? (
              <div
                onClick={() => this.appPermissions(true)}
                className="col-12 mb-3 align-self-center alert alert-warning"
              >
                {!permissions.audio ? "Microphone" : null}{" "}
                {!permissions.audio && !permissions.video ? "&" : null}{" "}
                {!permissions.video ? "Webcam" : null} permission is required
                for video conferencing. Click this panel to grant the access.{" "}
                <br />
                <strong>
                  If the browser permission dialog doesn&apos;t opens up then you
                  will have to manually give the permission by going to the
                  website preferences section of your browser.
                </strong>
              </div>
            ) : null}

            <div className="col align-self-center">
              <p>
                <strong>Hi {appointment.patient_name}!</strong>
              </p>
              <p>You have an appointment today</p>
            </div>
            <div className="col-auto pl-0">
              <figure className="logo">{SITE_LOGO}</figure>
            </div>

            <div className="col-12 my-3">
              <div className="card gray_gradient">
                <div className="row">
                  <div className="col">
                    <p>
                      <strong>{appointment.doctor_name}</strong>
                    </p>
                    <p>
                      {appointment.speciality_name != null
                        ? appointment.speciality_name.join(", ") + ", "
                        : null}
                      {appointment.hopital_name
                        ? appointment.hopital_name
                        : null}
                    </p>
                  </div>
                  <div className="col-auto">
                    <div className="time_card">
                      <Moment format="ddd" parse="YYYY-MM-DD HH:mm">
                        {appointment.booking_date_time}
                      </Moment>{" "}
                      <br />
                      <Moment format="D MMM" parse="YYYY-MM-DD HH:mm">
                        {appointment.booking_date_time}
                      </Moment>{" "}
                      <br />
                      <span>
                        <Moment format="h:mm A" parse="YYYY-MM-DD HH:mm">
                          {appointment.booking_date_time}
                        </Moment>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!appointment.prescription_url ? (
              <>
                <div className="col-12 p-3">
                  <div className="call_shortly text-center">
                    <p className="mb-0">
                      Please keep this screen open
                      <br />
                      <strong className="text-secondary">
                        doctor will call you shortly
                      </strong>
                    </p>
                  </div>
                </div>

                <div className="col-12">
                  <p>
                    Please upload any documents or notes that you would like to
                    share with the doctor.
                  </p>
                  <p className="mt-3 mb-0">
                    <strong>Notes for Doctor:</strong>
                  </p>
                </div>
                <div className="col-12">
                  <div className="card-textarea pb-0">
                    <textarea
                      onChange={(e) =>
                        this.setState({ notes: e.target.value.substr(0, 1000) })
                      }
                      rows="4"
                      maxLength="1000"
                      defaultValue={notes}
                    ></textarea>
                  </div>
                  <span className="float-end fw-lighter text-muted">
                    {notes.length} / 1000 characters
                  </span>
                </div>

                <div className="col-12 mb-3">
                  <Upload
                    action={
                      API_URL_IOS_V2 +
                      "bookings/" +
                      appointment.booking_id +
                      "/upload-document"
                    }
                    listType="picture-card"
                    headers={API_HEADERS}
                    // accept="image/png, image/jpeg, image/jpg, application/pdf"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={beforeUpload}
                  >
                    {/* {fileList.length >= 12 ? null : uploadButton} */}
                    {uploadButton}
                  </Upload>

                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    style={{ top: 10 }}
                    bodyStyle={{ padding: "5px" }}
                    width={"100%"}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                  >
                    <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
                      <img
                        style={{ maxWidth: "100%", maxHeight: "500px" }}
                        alt="Preview"
                        src={previewImage}
                      />
                    </div>
                  </Modal>
                </div>

                <div className="col-12 text-center">
                  {savingNotes ? (
                    LOADER_BTN
                  ) : (
                    <button
                      onClick={() => this.saveNotes()}
                      disabled={notes === ""}
                      id="uploadbtn"
                      className="btn-upload mb-1 mr-1"
                    >
                      UPDATE
                    </button>
                  )}
                </div>
              </>
            ) : null}

            {!appointment.prescription_url ? (
              <div className="col-12 my-3 text-center">
                <p className="mb-0">
                  <strong>
                    Your Prescription :{" "}
                    <span className="text-muted">AWAITED</span>
                  </strong>
                </p>
              </div>
            ) : null}

            {appointment.prescription_url ? (
              <>
                <div className="my-3 cta_wrap gray_gradient">
                  <div className="row">
                    <div className="col-12 my-3 text-center">
                      <p>
                        <strong>
                          Your Prescription :{" "}
                          <span className="text-secondary">AVAILABLE</span>
                        </strong>
                      </p>
                    </div>
                    <div className="col-12 text-center mb-3">
                      <button
                        onClick={() =>
                          this.setState({ prescriptionVisible: true })
                        }
                        className="btn btn-outline-primary mr-1"
                      >
                        <i>
                          <img alt="View Prescription" src={PdfIcon} />
                        </i>{" "}
                        View Prescription
                      </button>
                      {/* <button className="btn btn-outline-secondary mr-1">
                        <i>
                          <img alt="Share" src={ShareIcon} />
                        </i>
                        Share
                      </button> */}
                      <a
                        href={appointment.prescription_url}
                        target="_blank"
                        rel="noreferrer"
                        download={"prescription.pdf"}
                        className="btn btn-outline-secondary"
                      >
                        <i>
                          <img alt="Download" src={DownloadIcon} />
                        </i>
                        Download
                      </a>
                    </div>
                  </div>
                </div>

                <Modal
                  visible={prescriptionVisible}
                  title={"View Prescription"}
                  footer={null}
                  style={{ top: 10 }}
                  bodyStyle={{ padding: "5px" }}
                  width={"100%"}
                  onCancel={() => this.setState({ prescriptionVisible: false })}
                >
                  <div>
                    <iframe
                      style={{
                        height: "calc(100vh - 100px)",
                        overflow: "auto",
                      }}
                      title="View Prescription"
                      src={appointment.prescription_url}
                      frameBorder="0"
                      width="100%"
                    ></iframe>
                  </div>
                </Modal>
              </>
            ) : null}

            <div className="col-12 mt-5 text-center">
              <p className="mb-0">
                For more features download the MyHealthcare app
              </p>
            </div>

            {APP_ICONS}
          </div>
        </div>

        <Modal
          className="start-video-call"
          // okText="Join Video Call"
          // cancelText="Reject Call"
          // cancelButtonProps={{ style: { float: "left" } }}
          centered={true}
          visible={this.state.browserModal}
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
          footer={false}
          width={340}
        >
          <div className="col-12">
            {/* <div className="mb-3">{SITE_LOGO}</div> */}
            <h5>Browser Compatibility</h5>
            <p>
              For our application, you will experience uninterrupted and
              seamless video call experience on below listed browsers{" "}
            </p>
            <div className="mb-1 android-list">
              <strong>Android</strong>
              <ul className="mt-2 browser-list">
                <li className="mb-1">
                  <img
                    width={20}
                    src={GoogleChrome}
                    alt="Google Chrome"
                    className="mr-2"
                  />
                  &nbsp; Google Chrome
                </li>
                <li>
                  <img width={20} src={SamsungInternet} alt="Google Chrome" />
                  &nbsp; Samsung Internet
                </li>
              </ul>
            </div>
            <div className="mb-1">
              <strong>iOS</strong>
              <ul className="mt-2 browser-list">
                <li>
                  <img width={25} src={IosSafari} alt="Google Chrome" />
                  &nbsp; Safari
                </li>
              </ul>
            </div>
            <div className="mb-1">
              <strong>Desktop</strong>
              <ul className="mt-2 browser-list">
                <li className="mb-1">
                  <img width={20} src={GoogleChrome} alt="Google Chrome" />
                  &nbsp; Google Chrome
                </li>
                <li>
                  <img width={20} src={MozillaFirefox} alt="Google Chrome" />
                  &nbsp; Mozilla Firefox
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 mt-4 mb-2 text-center">
            <button
              onClick={() => this.setState({ browserModal: false })}
              className="btn btn-primary"
            >
              I Understand
            </button>
          </div>
        </Modal>

        <Modal
          className="start-video-call"
          // okText="Join Video Call"
          // cancelText="Reject Call"
          // cancelButtonProps={{ style: { float: "left" } }}
          centered={true}
          visible={this.state.callStartedModal}
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
          footer={false}
          width={340}
        >
          <div className="col-12 text-center">
            <div className="mb-3">{SITE_LOGO}</div>
            <h5>VIDEO CONFERENCING</h5>
            <p className="mb-2">
              <strong>{appointment.doctor_name}</strong> has started video call.
            </p>
            <p>
              Please click on “
              <span className="text-secondary">Join Video Call</span>” button to
              join the call.
            </p>
          </div>

          <div className="col-12 mt-4 mb-2 text-center">
            <button
              onClick={() => this.rejectCall()}
              className="btn btn-outline-secondary"
            >
              REJECT CALL
            </button>
            &nbsp;
            <button onClick={() => this.joinCall()} className="btn btn-primary">
              <i>
                <img src={VideoIcon} alt="" />
              </i>{" "}
              JOIN VIDEO CALL
            </button>
          </div>
        </Modal>

        {this.state.joinCall ? (
          <div className="full-window">
            <VideoConferencing
              vcInfo={vcInfo}
              patient={this.state.patient}
              booking_id={appointment.booking_id}
              ishomecare={false}
              location={this.props.location}
              onChange={this.receiveInParent}
              callEnded={() => this.callEnded()}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default Consultation;
